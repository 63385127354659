import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layout/layout';
import { Container } from 'reactstrap';
import { TextBlock } from '../../../components/shared/text-block';
import { PRIMARY_TEXT_COLOR } from '../../../constants';
import { Wrapper } from '../../../components/shared/wrapper';
import { Fullscreen } from '../../../components/fullscreen/fullscreen';
import { Background } from '../../../components/background/background';
import cover from '../../../../static/images/services/cover.png';
import { BadgeLanguage, TitleContainer, TitleText } from '../../../components/shared';
import Link from '../../../components/shared/link';
import * as QueryString from 'query-string';
import styled from 'styled-components';
import NotFoundPage from '../../404';

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 370px) {
        flex-direction: column;
    }
    justify-content: start;
    align-items: start;
`;

const ProjectManager = props => {
    //return 404 page (page disabled)
    return <NotFoundPage />;

    const [state, setState] = useState(true);
    useEffect(() => {
        let parsed = QueryString.parse(props.location.search);
        if (parsed.language === 'sl') {
            setState(false);
        }
    }, [props.location.search]);


    const changeLanguage = language => {
        if (state !== language) {
            window.location = `${window.location.pathname}?language=${language ? 'eng' : 'sl'}`;
        }
    };
    return (
        <Layout>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <Background bg={cover} design={'cover'} index={1} />
                <TitleContainer>
                    <TitleText>{state ? 'Project Manager' : 'Projektni vodja (M/Ž)'}</TitleText>
                </TitleContainer>
            </Fullscreen>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <BadgeLanguage>
                        <span className={state ? 'black' : null} onClick={() => changeLanguage(true)}>
                            Eng
                        </span>
                        <span className={state ? null : 'black'} onClick={() => changeLanguage(false)}>
                            Slo
                        </span>
                    </BadgeLanguage>
                    {state ? (
                        <>
                            <TextBlock
                                title={
                                    <>
                                        <p>We are looking for a project manager</p>
                                        <div className="mb-5">
                                            <span className="badge badge-warning mr-2">Project Manager </span>
                                        </div>
                                    </>
                                }
                            >
                                <p>
                                    We are looking for a mid to senior project manager who is used to leading a team, organizing work, communicating with clients. We are looking for someone who is used to solving problems and challenges, which have been brought by a project. Technical experience is highly desirable, especially the knowledge of agile development, modern project management programs (Jira, Excel, Google sheet, SharePoint), but this is not a deal breaker. If you are a person who has worked in difficult conditions, led complex non-technical projects and coordinated a range of people, we will be happy to have you in our team, as we believe that we can also teach you all the necessary skills. If you already have this experience, even better.                                </p>
                            </TextBlock>
                            <TextBlock title={'About us and about projects'}>
                                <ul>
                                    <li>We are the team Equaleyes which develops mobile and web applications. We are part of the international company nChain which has 4 offices (in London, Switzerland, Maribor and Ljubljana). About 250 people works in the company currently. </li>
                                    <li>The projects are very diverse. We develop mobile and web apps for various companies around the world (both locally and globally). In Slovenia, we have created a mobile and online bank for NKBM. If you are a client of this bank, you are most likely also using our product. If you have ever traveled in England, it is very likely that you were using the Minicabit platform for transport between the city and the airport. We were developing this platform as well.  </li>
                                    <li>We are also focused on new blockchain technologies (payment systems, digital identity, data notarization).  There will be a lot of projects in this field and you will be able to participate in those projects, if you are interested in such technologies of the future. </li>
                                </ul>
                            </TextBlock>

                            <TextBlock title={'What we offer'}>
                                <ul>
                                    <li>We try to follow the philosophy of "either you learn or earn" as much as possible. So if you are new in this field and you need more qualifications in the field of IT, we will be an exceptional career opportunity for you as the transfer of knowledge between our employees is top notch. If you are an experienced expert, you can expect an above-average compensation in the IT field within our region. </li>
                                    <li>The team you will work with is good and above all hearty. We are proud of the "douche-bag free" team. </li>
                                    <li>You can work remotely or in any office (MB, LJ, Switzerland and London). There are also a lot of trips, if this is something that makes you happy (as much as possible depending on the situation). </li>
                                    <li>We are working on important projects that are not only tied to earnings, but sincerely want to make a positive change in the world. Among other things, we are currently developing products for the blind and visually impaired people, for people with autism and products that help young parents to put their children to sleep and calm them down. </li>
                                    <li>We are also a leader in the field of blockchain, where we believe that we are creating products that will significantly shape the future (digital money, notarization of documents and digital identity...). </li>
                                    <li>Good work-life balance. We try to avoid unnecessary pressure and stress on projects. Overtime and working on weekends are the exception rather than the rule. Although, to be honest, this also sometimes (exceptionally) happens. </li>
                                    <li>We will respect your privacy, which means that you will not be photographed, tagged or posted on social media at work events or anywhere else without your permission. </li>
                                    <li>All other perks offered by most IT companies are also available here (regular promotions, flexible working hours, interesting projects, team buildings, sports (volleyball, football, fitness), coffee / fruit in the office, work equipment of good quality...). </li>
                                    <li>Parking (both in LJ and in MB office). </li>
                                    <li>A regular full-time employment with a 6-month probationary period. We are also open to the other kinds of employment (project work / s.p.). </li>
                                    <li>And other. </li>
                                </ul>
                            </TextBlock>

                            <TextBlock title={'What we expect'}>
                                <ul>
                                    <li>That you have an innate sense of organization. </li>
                                    <li>That teamwork suits you. </li>
                                    <li>That you can take responsibility for your work and that you are also proud of your work. </li>
                                    <li>That you respect the time of your colleagues, that you are punctual for meetings and that you know how to summarize the content of the meeting. </li>
                                    <li>That you respect both co-workers and clients. </li>
                                    <li>That you are receptive to learning, that you take criticism and advice seriously, and that you are also willing to share your knowledge with others. </li>
                                    <li>That you  share your opinion honestly, but that you have at the same time a feeling for people's emotions. That you are honest but not mean / rude. </li>
                                    <li>That you master the English language - especially that you are able to communicate effectively in writing via email, video call or live. </li>
                                    <li>That you know how to prioritize tasks (you know how to pick out what is important and what is not, what is necessary and what is not) </li>
                                    <li>Experience in IT areas are a big plus (agile development, Jira, kanban and knowledge of all other terminologies in the field of project management). </li>
                                    <li>Understanding people, a basic bullshit detector and more. :)</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'How do you know you will be successful?'}>
                                <ul>
                                    <li>After the end of the project, if the development team is satisfied, the customer is satisfied and the project is managed in time and financial terms, this is a clear victory.</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'What does the process of choosing the right candidate look like'}>
                                <ul>
                                    <li>You apply for the job via the website or email. </li>
                                    <li>We will respond to your e-mail soon and we will suggest a date for the interview. Conversations take place in relaxed environment  one-on-one via zoom or live. </li>
                                    <li>After the conversation, you may get a task for which you will have a few days to solve. </li>
                                    <li>This is followed by a final interview / decision, which ends either with a job offer or advice on how we can find ourselves somewhere in the future.</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Footnote'}>
                                <ul>
                                    <li>We put a lot of emphasis on privacy, therefore any communication between us will be confidential.</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Are you interested?'}>
                                <p>If the position interest you, make sure to apply as soon as possible!</p>
                                <ButtonWrapper>
                                    <Link to="https://forms.office.com/r/q5eimj2HPE" className="mt-2">
                                        <button className="btn btn-warning mr-2">Apply</button>
                                    </Link>
                                    <Link to="https://forms.office.com/r/NQ4BBQ084M" className="mt-2">
                                        <button className="btn btn-link">I want to know more</button>
                                    </Link>
                                </ButtonWrapper>
                            </TextBlock>
                        </>
                    ) : (
                        <>
                            <TextBlock
                                title={
                                    <>
                                        <p>Iščemo</p>
                                        <div className="mb-5">
                                            <span className="badge badge-warning mr-2">Projektni vodja (M/Ž)</span>
                                        </div>
                                    </>
                                }
                            >
                                <p>
                                    Iščemo mid to senior projektnega vodjo, ki je navajen/a vodenja ekip, organiziranja dela, komunikacije s strankami in razreševanja težav ter izzivov, ki jih prinese projektno delo. Tehnične izkušnje so zelo zaželene, predvsem poznavanje agilnega razvoja, sodobnih programov za vodenje projektov (Jira, Excel, Google sheet, SharePoint),  ampak to ni deal breaker. Če si oseba, ki je delala v zahtevnih razmerah, vodila kompleksne netehnične projekte in usklajevala paleto ljudi, te bomo tudi veseli, saj verjamemo, da te vseh potrebnih veščin lahko tudi naučimo. V kolikor te izkušnje že imaš, pa še toliko boljše.
                                </p>
                            </TextBlock>
                            <TextBlock title={'O nas in o projektih'}>
                                <ul>
                                    <li>Smo ekipa Equaleyes, ki pod okriljem nChaina razvijamo mobilne in spletne aplikacije. Smo del mednarodnega podjetja, ki obsega 4 pisarne, v Londonu, Švici, Mariboru in Ljubljani. Trenutno nas je vseh skupaj okrog 250.</li>
                                    <li>Projekti so zelo raznoliki. Razvijamo mobilne in spletne aplikacije za različna podjetja po svetu (tako lokalno kot globalno). V Sloveniji smo naredili mobilno in spletno banko za NKBM, tako da če si komitent te banke, najverjetneje tudi ti uporabljaš naš produkt. Če si kdaj potoval po Angliji, je velika verjetnost, da si za transport med mestom in letališčem uporabljal platformo minicabit, ki smo jo prav tako razvijali mi.</li>
                                    <li>Osredotočamo se tudi na področje novih blockchain tehnologij (plačilni sistemi, digitalna identiteta, notarizacija podatkov), tako da če te takšne tehnologije prihodnosti veselijo, bo precej projektov iz tega področja, na katerih boš lahko sodeloval/a.</li>
                                </ul>
                            </TextBlock>

                            <TextBlock title={'Kaj ponujamo'}>
                                <ul>
                                    <li>Kolikor je le mogoče poskušamo slediti filozofiji “either you learn or earn”. :) Tako da če si bolj nov/a na tem področju in potrebuješ več kvalitifikacij iz področja IT-ja, bomo zate izjemna karierna priložnost, saj je prenos znanja med zaposlenimi pri nas vrhunski. Če si izkušen maček/a, pa lahko pričakuješ nadpovprečno kompenzacijo za IT področje znotraj naše regije.</li>
                                    <li>Ekipa, s katero boš sodeloval, je dobra in predvsem srčna. Ponosni smo “na douche-bag free” kolektiv.</li>
                                    <li>Lahko delaš remote, ali v katerikoli pisarni (MB, LJ, Švica, in London). Tudi potovanj je precej, če te to veseli (kolikor so pač mogoča glede na situacijo).</li>
                                    <li>Delamo na pomembnih projektih, ki niso le vezani na zaslužek, temveč iskreno želijo narediti pozitivno spremembo na svetu. Med drugim trenutno razvijamo projekte za slepe in slabovidne, za ljudi z avtizmom in produkte, ki pomagajo mladim staršem pri uspavanju ter miritvi svojih otrok.</li>
                                    <li>Smo tudi vodilni na področju blockchaina, kjer verjamemo, da ustvarjamo produkte, ki bodo pomembno krojili prihodnost (digitalni denar, notarizacija dokumentov in digitalna identiteta,..).</li>
                                    <li>Dober work-life balance. Trudimo se izogibati nepotrebnim pritiskom in stresom pri projektih. Nadure in delo čez vikende so prej izjema kot pravilo. Čeprav, če smo iskreni se tudi to kdaj (izjemoma) zgodi. </li>
                                    <li>Spoštovali bomo tvojo zasebnost, kar pomeni, da te na službenih dogodkih ali kjerkoli drugje ne bomo slikali, tagali in objavljali na socialna omrežja brez tvojega dovoljenja.</li>
                                    <li>Vsi ostali perki, ki jih ponuja večina IT firm, so na voljo tudi pri nas (redna napredovanja, fleksibilen delovni čas, zanimivi projekti, team buildingi, službeno financirani športi - odbojka, nogomet, fitnes; kava/sadje v pisarni, kvalitetna delovna oprema,..).</li>
                                    <li>Parking (tako v LJ, kot v MB officu).</li>
                                    <li>Redna zaposlitev za polni delovni čas s 6-mesečno preizkusno dobo. Odprti smo tudi za druge načine sodelovanja (projektno delo / s.p).</li>
                                    <li>In še kaj bi se našlo</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Kaj pričakujemo'}>
                                <ul>
                                    <li>Da imaš prirojen smisel za organizacijo.</li>
                                    <li>Da ti ustreza timsko delo.</li>
                                    <li>Da lahko prevzameš odgovornost za svoje delo in da si na svoje delo tudi ponosen.</li>
                                    <li>Da spoštuješ čas svojih sodelavcev/k, si točen na sestankih in znaš dobro povzeti vsebino sestanka.</li>
                                    <li>To, da spoštuješ tako sodelavce/ke kot stranke.</li>
                                    <li>To, da si dovzeten za učenje, vzameš kritiko in nasvete resno in da si pripravljen tudi svoje znanje deliti tudi med ostalimi.</li>
                                    <li>To, da iskreno poveš svoje mnenje, a imaš hkrati občutek za čustva ljudi. Da si iskren, a ne zloben/ nesramen.</li>
                                    <li>Da obvladaš angleški jezik - predvsem da znaš komunicirati učinkovito pisno preko e-maila, videoklica ali v živo.</li>
                                    <li>Znaš prioritizirati naloge (znaš izluščiti kaj je pomembno in kaj ne, kaj je nujno in kaj ne).</li>
                                    <li>Izkušnje iz IT področja so velik plus (agile development, Jira, kanban ter poznavanje vseh ostalih terminologij na področju project managementa).</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Kako veš, da boš uspešen?'}>
                                <ul>
                                    <li>Če bo po koncu projekta razvijalska ekipa zadovoljna, stranka zadovoljna in projekt voden v časovnih ter finančnih rokih, je to čista zmaga.</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Kako izgleda postopek izbire pravega kandidata'}>
                                <ul>
                                    <li>Prijaviš se na delovno mesto preko spletne strani ali e-maila.</li>
                                    <li>V kratkem bomo odgovorili na tvoj e-mail in predlagali termin za razgovor. Razgovori potekajo sproščeno ena na ena preko zooma ali v živo.</li>
                                    <li>Po zaključku pogovora lahko sledi tudi naloga, kjer imaš nekaj dni časa da jo rešiš.</li>
                                    <li>Temu sledi zaključen intervju/odločitev, ki se konča ali s ponudbo za delovno mesto ali nasveti kako se lahko najdemo kje v prihodnosti.</li>
                                </ul>
                            </TextBlock>
                            <TextBlock title={'Opomba'}>
                                <p>Veliko damo na privatnost in zasebnost, tako da bo vsaka komunikacija med nami zaupna.</p>
                            </TextBlock>
                            <TextBlock title={'Te zanima?'}>
                                <p>V kolikor se prepoznate v zgornjem opisu vas vabimo, da se čimprej prijavite!</p>
                                <ButtonWrapper>
                                    <Link
                                        to="https://forms.office.com/r/peaTBbv844"
                                        className="mt-2"
                                    >
                                        <button className="btn btn-warning mr-2">Prijava</button>
                                    </Link>
                                    <Link to="https://forms.office.com/r/SzdrNdTctz" className="mt-2">
                                        <button className="btn btn-link">Zanima me več</button>
                                    </Link>
                                </ButtonWrapper>
                            </TextBlock>
                        </>
                    )}
                </Container>
            </Wrapper>
        </Layout>
    );
};

export default ProjectManager;
