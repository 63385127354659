import React from 'react';
import styled from 'styled-components';
import { PRIMARY_BG_COLOR, PRIMARY_TEXT_COLOR, TITLE_FONT } from '../constants';
import { Parallax, ParallaxLayer } from 'react-spring/addons';
import { Link } from 'gatsby';
import title from '../../static/images/404/404-TOP-min.png';
import phone from '../../static/images/404/404-bottom-min.png';
import { Navbar } from '../components/navbar/navbar';
import { Footer } from '../components/footer';

const Sorry = styled.h1`
    font-family: ${TITLE_FONT};
    font-size: 27px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: -1.9px;
    text-align: center;
    color: #4e5962;
    margin-top: 50px;

    a:hover {
        text-decoration: none;
    }

    @media (max-width: 768px) {
        font-size: 22px;
        margin-top: 50px;
    }
`;

const Back = styled.b`
    font-family: ${TITLE_FONT};
    font-size: 27px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: -1.9px;
    text-align: center;
    color: #4e5962;
    font-weight: 500;
    text-decoration: underline;

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const ImageTitle = styled.div`
  background-image: url("${title}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 600px;
`;

const ImagePhone = styled.div`
  background-image: url("${phone}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 600px;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const ParallaxContainer = styled.div`
    background-color: ${PRIMARY_TEXT_COLOR};
    height: 100%;
    overflow: auto;
`;

const ParallaxFooterLayer = styled(ParallaxLayer)`
    background-color: ${PRIMARY_BG_COLOR};
`;

const NotFoundPage = () => (
    <ParallaxContainer>
        <Parallax pages={1.3} scrolling={true} className="d-none d-lg-block">
            <ParallaxLayer offset={0} speed={0}>
                <Navbar theme={'dark'} />
            </ParallaxLayer>
            <ParallaxLayer offset={0.2} speed={0.1} factor={0.2}>
                <ImageTitle />
            </ParallaxLayer>
            <ParallaxLayer offset={0.4} speed={-0.15} factor={0.2}>
                <ImagePhone />
            </ParallaxLayer>
            <ParallaxFooterLayer offset={1} speed={0} factor={0.5}>
                <Footer />
            </ParallaxFooterLayer>
            <ParallaxLayer offset={0.1} speed={-0.1}>
                <Sorry>
                    Sorry, this page was not found.
                    <br />
                    <Link to="/">
                        <Back>Back</Back>
                    </Link>
                </Sorry>
            </ParallaxLayer>
        </Parallax>
        <Parallax pages={2} scrolling={true} className="d-block d-lg-none">
            <ParallaxLayer offset={0} speed={0}>
                <Navbar theme={'dark'} />
            </ParallaxLayer>
            <ParallaxLayer offset={0.2} speed={0.1} factor={0.2}>
                <ImageTitle />
            </ParallaxLayer>
            <ParallaxLayer offset={0.4} speed={-0.1} factor={0.2}>
                <ImagePhone />
            </ParallaxLayer>
            <ParallaxFooterLayer offset={1} speed={0} factor={1}>
                <Footer />
            </ParallaxFooterLayer>
            <ParallaxLayer offset={0.1} speed={-0.1}>
                <Sorry>
                    Sorry, this page was not found.
                    <br />
                    <Link to="/">
                        <Back>Back</Back>
                    </Link>
                </Sorry>
            </ParallaxLayer>
        </Parallax>
    </ParallaxContainer>
);

export default NotFoundPage;
